import './Navbar.css';

function Navbar (props) {

    const { activeNav } = props;

    return (
        <div className="navbar">
            <ul className="navigation">
                <li className="navigation-item" id="nav-home">
                    <a href="#home" className={activeNav === "home" ? "active-section" : "inactive-section"}>Home</a>
                </li>
                <li className="navigation-item" id="nav-about">
                    <a href="#about" className={activeNav === "about" ? "active-section" : "inactive-section"}>About</a>
                </li>
                <li className="navigation-item" id="nav-schedule">
                    <a href="#schedule" className={activeNav === "schedule" ? "active-section" : "inactive-section"}>Schedule</a>
                </li>
                <li className="navigation-item" id="nav-contact">
                    <a href="#contact" className={activeNav === "contact" ? "active-section" : "inactive-section"}>Contact</a>
                </li>
            </ul>
        </div>
    )
}

export default Navbar;