import './Layout.css';
import { Outlet } from 'react-router-dom';

function Layout() {

  return (
    <div className="app">
      <div className="body">
        <Outlet />
      </div>
    </div>
  );
}

export default Layout;