import {BrowserRouter, Route, Routes} from 'react-router-dom';

import Layout from './Layout';
import Page from '../content/Page/Page';


function App() {
  return (
    <BrowserRouter future={{ v7_startTransition: true, v7_relativeSplatPath: true }}>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Page />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;