import logo from '../../../assets/twocountylines_horizontal_black-1.png';
import './Home.css';

function Home (props) {

    const { innerRef } = props;

    return <>
    <div className="bg-image"/>
    <div className="home-content">
        <section ref={innerRef} id="home" className="home-section">
            <div>
                <img className="logo" src={logo} alt="Two County Lines Logo"/>
            </div>
            <a className="scroll-box" href="#schedule">
                <>View our schedule &nbsp;&nbsp;<i className="fa-solid fa-arrow-down"/></>
            </a>
        </section>
    </div>
    </>
}

export default Home;