import about from '../../../assets/about.jpg'
import './About.css';

function About (props) {

    const { innerRef } = props;

    return <>
        <section ref={innerRef} id="about" className="about-section section">
            <div className="section-backdrop left">
                <h1 className="section-title">About</h1>
                <div>
                    <>We are a unique electric guitar/vocal duo named Two County Lines, based in Southern Wisconsin and keeping it real with classic rock, blues, southern rock, country, and pop.</>
                    <br />
                    <br />
                    <img className="about-picture" src={about} alt="Steve and Deb"/>
                </div>
            </div>
        </section>
    </>
}

export default About;