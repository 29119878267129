import { useState, useEffect, useRef } from 'react';
import Navbar from '../../structural/Navigation/Navbar';
import Home from '../Home/Home';
import About from '../About/About';
import Schedule from '../Schedule/Schedule';
import Contact from '../Contact/Contact';

function Page () {

    const [activeNav, setActiveNav] = useState("home");

    const homeRef = useRef(null);
    const aboutRef = useRef(null);
    const scheduleRef = useRef(null);
    const contactRef = useRef(null);

    const handleScroll = () => {

        const windowY = window.scrollY;

        const homeOffset = homeRef.current.offsetTop;
        const aboutOffset = aboutRef.current.offsetTop;
        const scheduleOffset = scheduleRef.current.offsetTop;
        const contactOffset = contactRef.current.offsetTop;

        let closest = "home";
        let closestOffset = Math.abs(homeOffset - windowY);

        if (Math.abs(aboutOffset - windowY) < closestOffset) {
            closestOffset = Math.abs(aboutOffset - windowY)
            closest = "about"
        }
        if (Math.abs(scheduleOffset - windowY) < closestOffset) {
            closestOffset = Math.abs(scheduleOffset - windowY)
            closest = "schedule"
        }
        if (Math.abs(contactOffset - windowY) < closestOffset) {
            closestOffset = Math.abs(contactOffset - windowY)
            closest = "contact"
        }
        setActiveNav(closest);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [activeNav]);

    return <>
        <Home innerRef={homeRef}/>
        <Navbar activeNav={activeNav}/>
        <About innerRef={aboutRef}/>
        <Schedule innerRef={scheduleRef}/>
        <Contact innerRef={contactRef}/>
    </>
}

export default Page;