import './Contact.css';

function Contact (props) {

    const { innerRef } = props;

    return <>
        <section ref={innerRef} id="contact" className="schedule-contact section final-section">
            <div className="section-backdrop left">
                <h1 className="section-title">Contact</h1>
                <div>Currently, the best way to contact us is to message us on Facebook!</div>
                <br />
                <a className="facebook-link" href="https://www.facebook.com/twocountylines"><i className="fa-brands fa-facebook-f" style={{fontSize: "30px"}}/></a>
            </div>
        </section>
    </>
}

export default Contact;